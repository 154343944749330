.background-1 {
  height: 90vh;
  width: 100%;
  background-color: white;
  background-color: black !important;

  /* background-color: black !important; */

  color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;

}

/* body{
  overflow-x:hidden;
} */

.content-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh !important;
  background-color: black !important;
  color: white !important;

  /* background-color: pink !important; */

}

.h1 {
  font-size: 2.5rem;
  font-weight: 900;
  background-color: black !important;
  color: white !important;


}



.change_content:after {

  content: " Digital Marketing Solution";
  animation: changetext 10s infinite linear;
  background-color: black !important;
  color: #fc0c8c;
  font-weight: 900;
  font-size: 3rem;

}

@keyframes changetext {

  5% {
    content: " Digital Marketing";
  }

  20% {
    content: " Event Management support";
  }

  35% {
    content: " PR Solutions";
  }

  60% {
    content: " Models For Brand";
  }

  90% {
    content: " Asthetic Design";
  }

  70% {
    content: " Models For Brand";
  }

  40% {
    content: " Wedding Photography";
  }

  80% {
    content: " Fashion Solution";
  }

  50% {
    content: " Offbeat Stays ";
  }

}

.container-2 {
  height: 80%;
  color: white;
  background-color: black;

  /* background-color: aqua !important; */

}

.content-3 {

  height: 200px;

  width: 80%;
  color: white !important;
  ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #fc0c8c !important; */






}

.content-4 {
  display: flex;
  /* margin:auto; */
  justify-content: space-around;
  align-items: center;

  /* background-color: #fc0c8c !important; */

}

.my-2 {
  background-color: black !important;
}

.parent {
  position: fixed;
  top: 100px;

}

.bottom {
  font-size: 4rem;
  position: fixed;
  bottom: 50px;
  right: 15px;
  z-index: 100;
  color: #fc0c8c;
  background-color: black;
  width: 7%;
  height: 6%;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: center;
  border-radius: 33px;
  margin-left: 12px;
  cursor: pointer;


}

.box {
  height: 120px;
  width: 300px;
  color: black !important;
  margin: auto;



}

.box i {
  color: #fc0c8c;
  font-size: 1.7rem;
  font-weight: 900;

}

.boxes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1rem !important;
  font-weight: 700;
  /* background-color: #fc0c8c !important; */


}

.box :link {
  text-decoration: none !important;
}

.text1 {
  color: black !important;
}



@media screen and (max-width:590px) {
  .cont1 {
    display: none !important;
  }

  .content-3 {
    width: 100%;
  }

  .background-1 {
    max-height: 100%;
    font-size: 1rem;
    margin: auto;
    max-width: 100vw;

  }

  .content-2 {
    height: 30vh;
    text-align: center;


  }

  .boxes {
    width: 350px;
    height: 100px;
    font-size: 0.5rem !important;
  }

  .h1 {
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;






  }

  .container-2 {
    max-height: 600px;
  }

  .change_content:after {
    font-size: 1.6rem;


  }


  .bottom {
    /* bottom:100px; */
    /* right: 2px; */
    width: 19%;
    height: 4%;
    font-size: 0.8rem;


  }

  .content-3 {
    text-align: center;
    margin: auto;
  }

  .box {
    height: 120px;
    width: 350px;
    color: black !important;
    ;
    margin: auto;



  }

  .box i {
    font-size: 1.3rem;

  }

  .background-1 {
    height: 80vh;
  }
}


@media screen and (min-width:600px) and (max-width:900px) {


  .cont1 {
    display: none !important;
  }

  .container-2 {
    height: 90%;
    color: white;
    background-color: black;

    /* background-color: aqua !important; */

  }


  .background-1 {
    height: 80vh;
  }


  .content-2 {
    height: 40vh;
    text-align: center;


  }

  .boxes {
    height: 200px;
    font-size: 1.5rem;
    margin: auto;
    text-align: center;

  }

  .h1 {
    font-size: 4rem;
    font-weight: 900;

  }

  .change_content:after {
    font-size: 4rem;


  }

  .bottom {
    font-size: 2rem;
    /* bottom:370px; */
    right: 40px;
    width: 18%;
    height: 5%;
    font-size: 1rem;


  }

  .content-3 {
    width: 100%;
  }
}

@media screen and (min-width:768px) and (max-width:900px) {
  .container-2 {
    height: 90%;
    color: white;
    background-color: black;

    /* background-color: aqua !important; */

  }

  .background-1 {
    max-width: 100vw;
    margin: auto;
  }

  body {
    overflow-x: hidden;
  }

  .h1 {
    font-size: 3rem;
    font-weight: 900;

  }

  .change_content:after {
    font-size: 2rem;


  }


  .bottom {
    font-size: 1rem;
    bottom: 160px;
    right: 70px;
    width: 16%;
    height: 4%;



  }

  .content-3 {
    width: 100%;
  }
}

@media screen and (max-width:280px) {
  .background-1 {
    max-height: auto;
    width: 100%;


  }

  .h1 {
    font-size: 1.5rem;
  }

  .change_content:after {
    font-size: 1.5rem;
  }

  .content-3 {
    max-height: 900px;

  }

  .boxes {
    height: 70px;
    font-size: 0.6rem;


  }

  .content-2 {
    height: 20vh;
    text-align: center;


  }

  .bottom {
    font-size: 0.5rem;
    right: 20px;
    width: 20%;
    height: 4%;



  }
}

@media screen and (min-width:900px) and (max-width:1250px) {

  .bottom {
    font-size: 1rem;
    right: 20px;
    width: 10%;
    height: 6%;



  }

  .content-2 {
    height: 30vh;
    text-align: center;


  }

  .boxes {
    font-size: 1.3rem;
  }

  .content-3 {
    width: 100%;
  }

}
