main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    font-family: 'Roboto', sans-serif;
  }
  
  .cardsm {
    width: 20rem;
    height: 25rem;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color:#fff;
      font-weight:bold;
    box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* .cardsm:hover::after{
    content:'';
    height:100%;
    width:100%;
    position:absolute;
    background-color: rgba(0,0,0,0.1);
  } */
  
  .work_img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.9;
      transition: opacity .2s ease-out;
    }
  
   .card-contentse h2 {
      position: absolute;
      inset: auto auto 30px 30px;
      margin: 0;
      transition: inset .3s .3s ease-out;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: normal;
      text-transform: uppercase;
      color:#fff;
      font-weight:bold
    }
    
    .card-contentse p, .card-contentse a {
      position: absolute;
      opacity: 0;
      max-width: 80%;
      
      transition: opacity .3s ease-out;
      color:#fff;
      font-weight:bold
    }
    
    .card-contentse  p {
      inset: auto auto 80px 30px;
      text-align: center;
      color:#fff;
      font-weight:bold;
      background-color:rgba(0, 0, 0, 0.7);
      border-radius:15px;
      width:100%;
      
    }
    
    .card-contentse  a {
      inset: auto auto 40px 30px;
      color: inherit;
      text-decoration: none;
      color:#fff;
      font-weight:bold;
    }
    
    .cardsm:hover h2 {
      inset: auto auto 220px 30px;
      transition: inset .3s ease-out;
      color:#fff;
      font-weight:bold;
    }
    
    .cardsm:hover p, &:hover a {
      opacity: 1;
      transition: opacity .5s .1s ease-in;
    }
    
    .cardsm:hover .work_img {
      transition: opacity .3s ease-in;
      opacity: 1;
    }
  
  .material-symbols-outlined {
    vertical-align: middle;
  }
  
  
  @media screen and (max-width:376px){
    main{
      display:flex;
      flex-direction:column;
      justify-content: center;
    }
  
    .cardsm{
    margin-bottom: 1rem;
    }
  }
  
  
  @media screen and (max-width:415px) {
    main{
      display:flex;
      flex-direction:column;
      justify-content: center;
    }
  
    .cardsm{
    margin-bottom: 1rem;
    }
  }
  
  @media screen and (max-width:540px){
    main{
      display:flex;
      flex-direction:column;
      justify-content: center;
    }
  
    .cardsm{
    margin-bottom: 1rem;
    }
  }
  
  @media screen and (max-width:821px){
    main{
      display:flex;
      flex-direction:column;
    }
    .cardsm{
      margin-bottom: 1rem;
      }
  }
  
  